'use strict';

var mediaBreakpoints = require('../config/mediaBreakpoints.json');
var $cache = {
    body: $('body')
};

var utils = {
    /**
     * @desc Media breakpoints that are used throughout the Javascript
     */
    breakpoints: mediaBreakpoints,

    /**
     * @function
     * @description Returns either an object with all of the available breakpoints or a specific viewport based on the given size
     * @param {string=} size The viewport to return
     * @param {Object=} breakpoints A custom breakpoints object
     * @returns {Object|string} - breakpoints or specific viewport
     */
    getViewports: function (size, breakpoints) {
        var bps = breakpoints || this.breakpoints;

        if (size) {
            var viewport = bps[size];

            if (viewport) {
                return viewport;
            }

            window.console.error('Unexpected viewport size given in util.getViewports');
            throw new Error('Unexpected viewport size given in util.getViewports');
        } else {
            return bps;
        }
    },

    /**
     * @function
     * @desc Generates a min-width matchMedia media query based on the given params
     * @param {string} size - Breakpoint to use for the media query
     * @param {Object} breakpoints - Override of the util breakpoints (optional)
     * @returns {boolean} - Wether or not the given media query matches
     */
    mediaBreakpointUp: function (size, breakpoints) {
        var breakpoint = this.getViewports(size, breakpoints);
        var mediaQuery = window.matchMedia('(min-width: ' + breakpoint + 'px)');
        return mediaQuery.matches;
    },

    /**
     * @function
     * @description Returns the current viewport name (ex: 'medium') or 'max' if the current window is larger than any defined viewport width
     * @returns {string} - current viewport name
     */
    getCurrentViewport: function () {
        var w = window.innerWidth;
        var viewports = utils.getViewports();
        var viewport = 'max';
        // Traverse the object from small up to desktop, and return the first match
        for (let name in viewports) {
            if (w <= viewports[name]) {
                viewport = name;
                break;
            }
        }
        return viewport;
    },

    /**
     * @function
     * @description Returns the current viewport mode: mobile (includes tablet portrait) OR desktop (usually >=1024px)
     * @returns {string} - current viewport name
     */
    getCurrentLayout: function () {
        var isDesktop = window.innerWidth >= this.breakpoints.ls; // normally >=1024
        return isDesktop ? 'desktop' : 'mobile';
    },

    isCurrentPage: function (name) {
        return $cache.body.attr('data-page-id') === name;
    },

    /**
     * @function
     * @description Executes a callback function when the user has stopped resizing the screen.
     * @param {function} callback - Callback function for the resize event
     * @return  {function} - Callback function for the resize event
     */
    smartResize: function (callback) {
        var timeout;

        $(window).on('resize', function () {
            clearTimeout(timeout);
            timeout = setTimeout(callback, 200);
        });

        return callback;
    },

    /**
     * @function
     * @description Checks if device has touch features.
     * @return  {boolean} - result
     */
    isTouchDevice: function () {
        return (('ontouchstart' in window)
           || (navigator.maxTouchPoints > 0)
           || (navigator.msMaxTouchPoints > 0));
    },

    /**
     * @function
     * @desc Retrieves the util breakpoints object
     * @returns {Object} - All of the breakpoints
     */
    getBreakpoints: function () {
        return this.breakpoints;
    },

    scrollBrowser: function (xLocation, scrollDuration) {
        var duration = typeof scrollDuration === 'number' ? scrollDuration : 500;
        var scrollY = xLocation;

        if (window.stickyHeaderData && window.stickyHeaderData.enabled) {
            scrollY = xLocation - window.stickyHeaderData.height;
        }

        $('html, body').animate({ scrollTop: scrollY }, duration);
    },

    add: function (a, b) {
        return Math.round((a + b) * 100) / 100;
    }
};

module.exports = utils;
